<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('exportTrophy.profile_scrutiny_evaluation') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col sm="3">
            <b-form-group
              label-for="fiscal_year_id"
            >
              <template v-slot:label>
                {{$t('globalTrans.fiscal_year')}}
              </template>
              <b-form-select
                plain
                v-model="search.fiscal_year_id"
                :options="fiscalYearList"
                id="fiscal_year_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="3">
              <b-form-group
                label-for="type"
              >
              <template v-slot:label>
                {{$t('globalTrans.type')}}
              </template>
              <b-form-select
                plain
                v-model="search.sector_type"
                :options="productServiceTypeList"
                id="type"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="3">
              <b-form-group
                label-for="product_service_sector_id"
              >
              <template v-slot:label>
                {{$t('externalUser.export_product_sector')}}
              </template>
              <v-select name="production_season_id"
                v-model="search.product_service_sector_id"
                label="text"
                :reduce="item => item.value"
                :options= productServiceSectorList
                :placeholder="$t('globalTrans.select')"
              />
              </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-button class="mt-20" size="sm" variant="primary" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('exportTrophy.profile_scrutiny_evaluation') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <button v-if="showApplicationList" style="padding:0.1rem 0.5rem" class="btn-add mr-2" v-b-modal.modal-evaluated-application-list>{{ $t('exportTrophy.evaluated_application_list') }}</button>
        <a href="javascript:" class="btn-add mr-2" v-b-modal.modal-meeting-resolution>{{ $t('cip.meeting_resolution') }}</a>
        <a v-if="showSendBtn" href="javascript:" class="btn-add mr-2" v-b-modal.modal-remark>{{ $t('cip.send_for_profile_verification') }}</a>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col md="12" class="table-responsive">
              <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                <template v-slot:cell(index)="data">
                  {{ $n(data.index + pagination.slOffset) }}
                </template>
                <template v-slot:cell(total_application) = "data">
                  {{ $n(data.item.total_application, { useGrouping: false }) }}
                </template>
                <template v-slot:cell(action)="data">
                  <b-button v-b-modal.details variant=" action-btn status" size="sm" @click="showApplicant(data.item.product_service_sector_id, data.item.circular_id)" class="action-btn status" :class="{ 'btn-primary': (data.item.total_application === data.item.total_evaluated_application) }" :title="$t('globalTrans.details')"><i class="ri-eye-fill"></i></b-button>
                  <b-button v-if="data.item.total_application === data.item.total_evaluated_application" v-b-modal.modal-evaluation-sheet variant=" action-btn status" size="sm" @click="setListItem(data.item)" class="action-btn status btn-info" :title="$t('exportTrophy.evaluation_sheet')"><i class="ri-trophy-line"></i></b-button>
                </template>
              </b-table>
              <div class="pagination-wrapper mt-3">
                <b-pagination
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-meeting-resolution" size="lg" :title="$t('cip.prev_meeting_resolution')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <MeetingResolution :circular_id="circularId" :key="circularId"/>
    </b-modal>
    <b-modal id="modal-remark" size="md" :title="$t('globalTrans.remark')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Remark :circular_id="circularId" :current_status="6" :status="7" :menu_name_id="2" :key="circularId"/>
    </b-modal>
    <b-modal id="modal-details" size="xl" :title="$t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-evaluated-application-list" size="xl" :title="$t('exportTrophy.evaluated_application_list')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <template #modal-title>
        {{ $t('exportTrophy.evaluated_application_list') }} <b-button size="sm" variant="success" class="float-right" @click="pdfExport">{{ $t('globalTrans.export_pdf') }}</b-button>
      </template>
      <EvaluatedApplicationList :circular_id="circularId" :key="circularId" ref="evaluatedAppliactionList"/>
    </b-modal>
    <b-modal id="modal-evaluation-sheet" size="xl" :title="$t('exportTrophy.evaluation_sheet')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <template #modal-title >
        <div class="d-flex justify-content-between">
          {{ $t('exportTrophy.evaluation_sheet') }}
          <div>
            <export-excel
                  class="btn btn-info btn-add mr-2"
                  :title="headerValue"
                  default-value="headerExcelDefault"
                  :data="excelData"
                  worksheet="Report Sheet"
                  name="evaluation_sheet.xls">
                  {{ $t('globalTrans.export_excel') }}
            </export-excel>
            <b-button variant="success" @click="pdfExportEvaluationSheet" class="mr-2">
            {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </div>
        </div>
      </template>
      <EvaluationSheet :list-item="listItem" :key="search.fiscal_year_id" ref="evaluationSheet" @get-excel-data="getExcelData" />
    </b-modal>
  </div>
</template>
<script>
import Remark from '../Remark.vue'
import Details from '../Details.vue'
import MeetingResolution from '../MeetingResolution.vue'
import EvaluatedApplicationList from '../EvaluatedApplicationList.vue'
import EvaluationSheet from './EvaluationSheet.vue'
import RestApi, { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
import { scrutinyEvaluationApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Remark,
    MeetingResolution,
    Details,
    EvaluatedApplicationList,
    EvaluationSheet
  },
  data () {
    return {
      baseUrl: exportTrophyCIPServiceBaseUrl,
      showSendBtn: false,
      overallApplication: 0,
      circularId: 0,
      search: {
        fiscal_year_id: 0,
        sector_type: 0,
        product_service_sector_id: '',
        fiscal_year_name: '',
        limit: 20
      },
      previous_remarks: {
        remark: '',
        attachment: ''
      },
      labelData: [],
      productServiceSectorList: [],
      status: 0,
      noteTitle: '',
      showApplicationList: false,
      listItem: {
        product_service_sector_id: 0,
        circular_id: 0
      },
      headerValue: [],
      headerExcelDefault: {},
      excelData: []
    }
  },
  created () {
    this.search.fiscal_year_id = this.$store.state.currentFiscalYearId
    this.loadData()
  },
  watch: {
    'search.sector_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search.product_service_sector_id = ''
        this.productServiceSectorList = this.getProductServiceSectorList(newVal)
      }
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.productServiceLocaleChange()
      }
    }
  },
  computed: {
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList
    },
    productServiceTypeList () {
      return this.$store.state.ExportTrophyCipService.commonObj.productServiceTypeList.map(el => {
        return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
      })
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('externalUser.export_product_sector'), class: 'text-center' },
          { label: this.$t('globalTrans.total_application'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'product_service_name_bn' },
          { key: 'total_application' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'product_service_name_en' },
          { key: 'total_application' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    setListItem (item) {
      this.listItem = Object.assign({}, {
        product_service_sector_id: item.product_service_sector_id,
        circular_id: item.circular_id
      })
    },
    showApplicant (productServiceId, circularId) {
      this.$router.push({
        path: `/export-trophy-cip-service/export-trophy/scrutiny-evaluation-applicants?id=${productServiceId}&&circular_id=${circularId}`
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, scrutinyEvaluationApi, params)
      if (result.success) {
        this.$store.dispatch('setList', this.getRelationalData(result.data.data))
        this.paginationData(result.data, this.search.limit)
        // send to NBR button show when NBR application verified
        if (this.overallApplication === result.total_app) {
          this.showSendBtn = true
        } else {
          this.showSendBtn = false
        }
        if (result.total_app > 0) {
          this.showApplicationList = true
        } else {
          this.showApplicationList = false
        }
        this.circularId = result.data.data[0].circular_id
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
        this.showSendBtn = false
        this.showApplicationList = false
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      let total = 0
      const productServiceList = this.$store.state.ExportTrophyCipService.commonObj.exportSectorList
      const listAll = data.map((item, index) => {
        total = total + item.total_application
        const productService = productServiceList.find(tmp => tmp.value === item.product_service_sector_id)

        const productServiceData = {
          serial: index,
          product_service_name_en: productService !== 'undefined' ? productService.text_en : '',
          product_service_name_bn: productService !== 'undefined' ? productService.text_bn : ''
        }
        return Object.assign({}, item, productServiceData)
      })
      this.overallApplication = total
      return listAll
    },
    getProductServiceSectorList (sectorType) {
      return this.$store.state.ExportTrophyCipService.commonObj.exportSectorList.filter(sector => sector.type === sectorType && sector.assign_for === 2)
    },
    productServiceLocaleChange () {
      this.productServiceSectorList = this.productServiceSectorList.map(el => {
        return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
      })
    },
    pdfExport () {
      this.$refs.evaluatedAppliactionList.pdfExport()
    },
    pdfExportEvaluationSheet () {
      this.$refs.evaluationSheet.pdfExport()
    },
    getExcelData (obj) {
      this.headerValue = obj.headerValue
      this.headerExcelDefault = obj.headerExcelDefault
      this.excelData = obj.excelData
    }
  }
}
</script>
<style>
  .modal-title {
    width: 100%
  }
</style>
